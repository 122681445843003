<template>
  <div class="Ramipril">
    <div
        class="game-wrapper"
        :class="[
        { stage1: stage === 1 },
        { stage2: stage === 2 },
        { stage3: stage === 3 },
        { stage4: stage === 4 },
        { stage5: stage === 5 },
        { stage6: stage === 6 },
      ]"
    >

      <div class="game-wrapper__stage " v-show="stage === 1">

        <div class="game-wrapper__stage--white-block stage1">
          <p class="game-wrapper__stage--text  green"><span>І навіщо ти поклав рецепт лікаря до кишені?<br>Все зімʼялось…</span>
          </p>

          <div class="game-wrapper__stage--arrow-down"></div>
        </div>

        <div class="game-wrapper__stage--img-circle">
          <img src="../../assets/img/game-ramipril/stage-1-circle.png" alt="">
        </div>

        <div class="game-wrapper__stage--btn-next-stage hover_type6" @click="nextStage(2)">
          <p>
            Далі
          </p>
        </div>

      </div>

      <div class="game-wrapper__stage " v-show="stage === 2">

        <div class="game-wrapper__stage--white-block stage2">
          <p class="game-wrapper__stage--text  green desk"><span>Так, виглядає не дуже... Проте впевнений,<br>що в аптеці все зрозуміють</span>
          </p>
          <p class="game-wrapper__stage--text  green mobile"><span>Так, виглядає не дуже...<br>Проте впевнений, що в аптеці все зрозуміють</span>
          </p>

          <div class="game-wrapper__stage--arrow-down"></div>
        </div>

        <div class="game-wrapper__stage--img-circle">
          <img src="../../assets/img/game-ramipril/stage-2-circle.png" alt="">
        </div>

        <div class="game-wrapper__stage--btn-next-stage hover_type6" @click="nextStage(3)">
          <p>
            До аптеки
          </p>
        </div>

      </div>

      <div class="game-wrapper__stage " v-show="stage === 3">

        <div class="game-wrapper__stage--white-block stage3">
          <p class="game-wrapper__stage--text blue"><span>Добрий день!<br>
Я Вас уважно слухаю. Чим можу допомогти?</span></p>

          <div class="game-wrapper__stage--arrow-down"></div>
        </div>

        <div class="game-wrapper__stage--img-circle">
          <img src="../../assets/img/game-ramipril/stage-3-circle.png" alt="">
        </div>

        <div class="game-wrapper__stage--btn-next-stage hover_type6" @click="nextStage(4)">
          <p>
            Вислухати побажання
          </p>
        </div>

      </div>

      <div class="game-wrapper__stage " v-show="stage === 4">

        <div class="game-wrapper__stage--white-block stage4">
          <p class="game-wrapper__stage--text green"><span>Тут скоріше треба не слухати, а гарно придивитись.<br>У нас зімʼявся рецепт…</span>
          </p>

          <div class="game-wrapper__stage--arrow-down"></div>
        </div>

        <div class="game-wrapper__stage--img-circle">
          <img src="../../assets/img/game-ramipril/stage-4-circle.png" alt="">
        </div>

        <div class="game-wrapper__stage--btn-next-stage hover_type6 small-p" @click="nextStage(5)">
          <p>
            Переглянути<br>
            призначення
          </p>
        </div>

      </div>

      <div class="game-wrapper__stage " v-show="stage === 5">

        <div class="game-wrapper__stage--white-block popup custom-p">

          <div class="game-wrapper__stage--top-text big">
            Як же часто Вам доводиться бути у ролі перекладача, дешифратора, а іноді навіть детектива.
            Перед Вами рецепт, який потрібно прочитати
            Проте не все так просто.
          </div>

          <div class="game-wrapper__stage--bottom-text big">
            Спробуєте?
          </div>

          <div class="game-wrapper__stage--btn-next-stage hover_type6 auto-m" @click="nextStage(6)">
            <p>Вперед!</p>
          </div>
        </div>

      </div>

      <RamiprilGame v-if="stage === 6"></RamiprilGame>
    </div>

    <div class="notes-wrapper" v-show="stage !== 6" :class="{ active: stage===5 }">
      <span>Інформація про лікарський засіб. РП №UA/16689/01/02, №UA/16689/01/03. Для професійної діяльності медичних та
      фармацевтичних працівників.</span>


      <span>Раміприл-Тева (1 таблетка містить 5 мг або 10 мг раміприлу). Характеристики: таблетки по 5 мг: рожеві, продовгуваті, двоякоплоскі таблетки з двома лініями розлому з боків; верхній пуансон: паралельна лінія розлому, тиснення R/3, з фаскою; нижній пуансон: з фаскою; 8,8 мм х 4,4 мм; таблетки по 10 мг: білі або майже білі, продовгуваті, двоякоплоскі таблетки з двома лініями розлому з боків; верхній пуансон: паралельна лінія розлому, тиснення R/4 та фаска; нижній пуансон: з фаскою; 11,0 мм х 5,5 мм. Лікувальні властивості: інгібітор ангіотензинперетворювального ферменту (АПФ). Інгібітор АПФ монокомпонентний. Найбільш частими побічними реакціями є: головний біль, запаморочення, непродуктивний подразнювальний кашель, бронхіт, синусит, задишка, запальні явища у шлунково-кишковому тракті, розлади травлення, дискомфорт у животі, диспепсія, діарея, нудота, блювання, висипання, зокрема макуло-папульозні, м’язові спазми, міалгія, підвищення рівня калію у крові, артеріальна гіпотензія, ортостатичне зниження артеріального тиску, синкопе, біль у грудях, втомлюваність.</span>

      <span>Контакти: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В, поверх 9; 0-800-502-284</span>

      <span>CBG-UA-00827. Термін дії матеріалу – 23.08.2024.</span>
    </div>

    <img
        v-if="
        stage === 1 || stage === 2 || stage === 3 || stage === 4 || stage === 6
      "
        class="bg"
        src="../../assets/img/game-ramipril/bg.png"
        alt=""
    />

    <img
        v-else
        class="bg"
        src="../../assets/img/game-clopidogrel/bg-type-2.jpg"
        alt=""
    />
  </div>
</template>

<script>
import RamiprilGame from "../../components/Ramipril-game.vue";

export default {
  name: "Ramipril",

  data: () => ({
    stage: 1,
  }),

  components: {
    RamiprilGame,
  },

  methods: {
    nextStage(stage) {
      this.stage = stage;
      window.scrollTo(0, 0)

    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/style/fonts.css";
@import "../../assets/style/vars";

.Ramipril {
  position: relative;
  min-height: calc((var(--vh, 1vh) * 100) - var(--height_header));
  height: 100%;

  display: flex;
  flex-direction: column;

  .bg {
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

  }

  .game-wrapper {
    height: 100%;
    max-width: 1270px;
    width: 100%;
    margin: 0 auto;

    padding: 40px 20px;

    &.stage6 {
      padding: 0;
    }

    &__stage {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 100%;
      position: relative;

      &--white-block {
        max-width: 610px;
        width: 100%;

        background: #FFFFFF;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);

        display: flex;
        justify-content: center;
        align-items: center;

        padding: 30px 20px;
        border-radius: 14px;

        position: absolute;
        z-index: 2;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 0;

        &.bottom {
          top: initial;
          bottom: 0;
        }

        &.custom-p {
          padding: 0;
          padding-bottom: 35px;
        }

        &.popup {
          position: relative;
          top: 0;
          display: flex;
          flex-direction: column;
          border-radius: 0;

          img {
            max-width: 280px;
            margin: 0 auto;
            width: 100%;
            height: auto;
            display: block;
          }
        }

        &.stage3 {
          img {
            max-width: 280px;
            width: 100%;
          }
        }
      }

      &--text {
        font-size: 20px;
        line-height: 120%;
        font-family: $M;
        text-align: center;

        max-width: 610px;
        width: 100%;

        span {
          font-family: $EB;
        }

        &.mobile {
          display: none;
        }

        &.green {
          color: $green;
        }

        &.blue {
          color: $blue;
        }
      }

      &--img-circle {
        position: relative;
        max-width: 100%;
        width: 100%;

        @media screen and (max-width: 425px) {
          max-width: 274px;
        }

        img {
          margin: 0 auto;

          position: relative;
          z-index: 1;

          max-width: 358px;
          width: 100%;
          height: auto;

          display: block;

          margin-top: 40px;

          box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
          border-radius: 50%;

          @media screen and (max-width: 425px) {
            max-width: 100%;
          }
        }
      }

      &--btn-next-stage {
        border: 2px solid $green;
        background: white;
        border-radius: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 47px;

        margin-top: 50px;
        padding: 14px 15px;

        max-width: 230px;
        width: 100%;

        cursor: pointer;

        &.small-m {
          margin-top: 30px;
        }

        &.small-p {
          padding-top: 2px;
          padding-bottom: 3px;

          p {
            line-height: 16px !important;
          }
        }

        &.auto-m {
          margin: auto 0 0 0;
        }

        p {
          color: $green;

          font-family: $EB;
          font-size: 16px;
          line-height: 22px;
          text-align: center;
        }
      }

      &--popup-text {
        margin-top: 20px;
        text-align: center;
        font-size: 16px;
        line-height: 120%;
        font-family: $M;
        color: $grey;

        span {
          font-family: $EB;
        }
      }

      &--arrow-down {
        position: absolute;
        bottom: -9px;
        width: 0;
        height: 0;
        border-left: 24px solid transparent;
        border-right: 24px solid transparent;

        border-top: 10px solid white;
      }

      &--top-text {
        background: $teal;
        padding: 40px 30px 40px 30px;
        text-align: center;

        font-family: $R;
        font-size: 24px;
        line-height: 120%;
        color: white;

        &.big {
          font-size: 32px;
        }
      }

      &--bottom-text {
        color: #424242;
        font-size: 24px;
        line-height: 120%;

        font-family: $M;

        padding: 48px 30px;
        text-align: center;

        &.big {
          font-family: $B;
          font-size: 40px;
        }
      }
    }
  }

  .notes-wrapper {
    max-width: 1280px;
    width: 100%;
    margin: auto auto 0 auto;

    padding: 16px 20px;

    font-size: 8px;
    line-height: 8px;
    font-family: $M;
    color: $grey;

    span {
      display: block;
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.active {
      color: white;
    }
  }
}

@media screen and (max-width: 600px) {
  .Ramipril {

    padding-top: 0;

    .bg {

    }

    .game-wrapper {
      padding: 20px 20px 0 20px;

      &.stage3 {
      }

      &__stage {

        &--white-block {
          padding: 10px;
          height: 90px;

          &.bottom {
            height: 70px;
          }

          &.stage5 {
            height: auto;
          }

          &.custom-p {
          }

          &.popup {
            padding-bottom: 17px;
            height: auto;
          }
        }

        &--text {
          font-size: 18px;
          line-height: 20px;

          &.mobile {
            display: block;
          }

          &.desk {
            display: none;
          }
        }

        &--img-circle {

          img {
            margin-top: 55px;
          }


          &.mr-top {
            margin-top: 0;

            img {
              margin-top: 0;
            }
          }

        }

        &--btn-next-stage {

          margin-top: 25px;
          height: 50px;

          &.small-p {

          }

          &.auto-m {
          }

          p {
            font-size: 16px;
            line-height: 22px;

          }
        }

        &--arrow-down {

        }

        &--top-text {
          font-size: 16px;
          line-height: 120%;
          padding: 28px 20px;
          font-family: $M;

          &.big {
            font-size: 20px;
          }
        }

        &--bottom-text {
          font-size: 14px;
          line-height: 120%;
          padding: 20px 20px 20px 20px;

          &.big {
            font-family: $M;
            font-size: 24px;
          }
        }
      }
    }

    .notes-wrapper {

      padding-top: 23px;
      font-size: 8px;
      line-height: 8px;
      padding-left: 10px;
      padding-right: 10px;

    }
  }
}
</style>
