<template>
  <div class="Ramipril">
    <div class="game-stage1" v-show="!result.win && !result.lose">
      <div class="game-bar">
        <div class="game-bar__timer-section">
          <svg
            :class="[{ red: time <= 10 }]"
            viewBox="0 0 28 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="14"
              cy="18.3333"
              r="13"
              stroke="#00A03B"
              stroke-width="2"
            />
            <path d="M17.7913 1H10.208" stroke="#00A03B" stroke-width="2" />
            <path
              d="M14 31.3333L14 28.0833"
              stroke="#00A03B"
              stroke-width="2"
            />
            <path
              d="M14 8.58331L14 5.33331"
              stroke="#00A03B"
              stroke-width="2"
            />
            <path
              d="M1 18.3333L4.25 18.3333"
              stroke="#00A03B"
              stroke-width="2"
            />
            <path
              d="M23.75 18.3333L27 18.3333"
              stroke="#00A03B"
              stroke-width="2"
            />
            <path
              d="M4.80737 9.14093L7.10547 11.439"
              stroke="#00A03B"
              stroke-width="2"
            />
            <path
              d="M20.8943 25.2277L23.1924 27.5258"
              stroke="#00A03B"
              stroke-width="2"
            />
            <path
              d="M23.1926 9.14093L20.8945 11.439"
              stroke="#00A03B"
              stroke-width="2"
            />
            <path
              d="M7.10571 25.2277L4.80762 27.5258"
              stroke="#00A03B"
              stroke-width="2"
            />
            <path
              d="M14.0003 18.7246L18.6045 10.75"
              stroke="#00A03B"
              stroke-width="2"
            />
          </svg>

          <timer :class="[{ red: time <= 10 }]" :time="prettyTime"></timer>
        </div>
      </div>

      <div class="game">
        <div class="game__image">
          <picture>
            <source
              srcset="../assets/img/game-ramipril/reb-mob.svg"
              media="(max-width: 560px)"
            />
            <img src="../assets/img/game-ramipril/reb.svg" alt="" />
          </picture>
        </div>

        <form class="game__form" @submit.prevent="onSubmit">
          <input v-model.trim="text" @input="onInput" type="text" />

          <button type="submit" :disabled="!text.length">
            <svg width="32" height="26" viewBox="0 0 32 26" fill="none">
              <path d="M2 15.5L9.5 23L30.5 2" stroke="white" stroke-width="4" />
            </svg>
          </button>
        </form>
      </div>

      <div class="game__tip">
        <span class="grey">відгадайте та введіть слово у поле вище</span>
      </div>
    </div>

    <div class="win" v-show="result.win">
      <div class="win__green-block blue stage1">
        <p class="win__text green">
          <span>Раміприл!</span>
          <br />
          Це те, що призначив Вам лікар
        </p>

        <div class="win__arrow-down blue"></div>
      </div>

      <img
        class="win__img-circle"
        src="../assets/img/game-ramipril/win.png"
        alt=""
      />

      <div class="win__text-center">
        Абсолютно вірно! Ви розгадали черговий ребус&nbsp;– і клієнти задоволені
      </div>

      <div class="win__info-text-btn">
        <div class="win__info-text">
          <p>
            <span>Раміприл-Тева </span>
            – для лікування артеріальної гіпертензії та профілактики
            серцево-судинних хвороб: зниження серцево-судинної захворюваності та
            летальних випадків у пацієнтів з вираженим серцево-судинним
            захворюванням атеротромботичного генезу та діабетом, які мають
            щонайменше один фактор серцево-судинного ризику*<sup>1</sup>
          </p>
        </div>
        <div class="win__info-btn" @click="winPopup = !winPopup">
          <svg
            width="46"
            height="46"
            viewBox="0 0 46 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="23" cy="23" r="23" fill="#35786E" />
            <path
              d="M21 14.0109C21 12.6703 21.6667 12 23 12C24.3333 12 25 12.6703 25 14.0109C25 14.6497 24.8313 15.1491 24.494 15.509C24.1647 15.8599 23.6667 16.0353 23 16.0353C21.6667 16.0353 21 15.3605 21 14.0109ZM24.8313 33H21.1566V17.9113H24.8313V33Z"
              fill="white"
            />
          </svg>
        </div>
      </div>

      <div class="win__popup" v-show="winPopup">
        <div class="win__popup--close-btn" @click="winPopup = !winPopup">
          <svg
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="13" cy="13" r="12.5" stroke="#00A03B" />
            <path d="M19.3643 6.63599L6.63634 19.3639" stroke="#00A03B" />
            <path d="M19.3643 19.3639L6.63634 6.63597" stroke="#00A03B" />
          </svg>
        </div>

        <div class="win__popup--text">
          *З повним переліком показань детальніше можна ознайомитись в
          інструкції для медичного застосування лікарського засобу.
        </div>
        <div class="win__popup--text">
          1. Інструкція для медичного застосування лікарського засобу
          РАМІПРИЛ-ТЕВА, таблетки по 2,5 мг. РП №UA/16689/01/01. Наказ МОЗ
          України №2032 від 04.09.2020.
        </div>
        <div class="win__popup--text">
          Інформація про лікарський засіб. РП №UA/16689/01/02, №UA/16689/01/03.
          Для професійної діяльності медичних та фармацевтичних працівників.
        </div>
        <div class="win__popup--text">
          Раміприл-Тева (1 таблетка містить 5 мг або 10 мг раміприлу).
          Характеристики: таблетки по 5 мг: рожеві, продовгуваті, двоякоплоскі
          таблетки з двома лініями розлому з боків; верхній пуансон: паралельна
          лінія розлому, тиснення R/3, з фаскою; нижній пуансон: з фаскою; 8,8
          мм х 4,4 мм; таблетки по 10 мг: білі або майже білі, продовгуваті,
          двоякоплоскі таблетки з двома лініями розлому з боків; верхній
          пуансон: паралельна лінія розлому, тиснення R/4 та фаска; нижній
          пуансон: з фаскою; 11,0 мм х 5,5 мм. Лікувальні властивості: інгібітор
          ангіотензинперетворювального ферменту (АПФ). Інгібітор АПФ
          монокомпонентний. Найбільш частими побічними реакціями є: головний
          біль, запаморочення, непродуктивний подразнювальний кашель, бронхіт,
          синусит, задишка, запальні явища у шлунково-кишковому тракті, розлади
          травлення, дискомфорт у животі, диспепсія, діарея, нудота, блювання,
          висипання, зокрема макуло-папульозні, м’язові спазми, міалгія,
          підвищення рівня калію у крові, артеріальна гіпотензія, ортостатичне
          зниження артеріального тиску, синкопе, біль у грудях, втомлюваність.
        </div>
        <div class="win__popup--text">
          Контакти: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В,
          поверх 9; 0-800-502-284
        </div>
        <div class="win__popup--text">
          CBG-UA-00827. Термін дії матеріалу – 23.08.2024.
        </div>
      </div>
    </div>

    <div class="lose" v-show="result.lose">
      <div class="lose__red-block stage1">
        <p class="lose__text desk white">
          <span
            >Ви точно знаєте правильну відповідь,<br />
            проте погляньте ще раз!
          </span>
        </p>

        <div class="lose__arrow-down"></div>
      </div>

      <img
        class="lose__img-circle"
        src="../assets/img/game-atorvastatin/lose.png"
        alt=""
      />

      <div class="lose__repeat hover_type6" @click="reset()">
        <p>
          Ще раз!
        </p>
      </div>
    </div>

    <div v-show="result.lose" class="notes-wrapper">
      <span
        >Інформація про лікарський засіб. РП №UA/16689/01/02, №UA/16689/01/03.
        Для професійної діяльності медичних та фармацевтичних працівників.</span
      >

      <span
        >Раміприл-Тева (1 таблетка містить 5 мг або 10 мг раміприлу).
        Характеристики: таблетки по 5 мг: рожеві, продовгуваті, двоякоплоскі
        таблетки з двома лініями розлому з боків; верхній пуансон: паралельна
        лінія розлому, тиснення R/3, з фаскою; нижній пуансон: з фаскою; 8,8 мм
        х 4,4 мм; таблетки по 10 мг: білі або майже білі, продовгуваті,
        двоякоплоскі таблетки з двома лініями розлому з боків; верхній пуансон:
        паралельна лінія розлому, тиснення R/4 та фаска; нижній пуансон: з
        фаскою; 11,0 мм х 5,5 мм. Лікувальні властивості: інгібітор
        ангіотензинперетворювального ферменту (АПФ). Інгібітор АПФ
        монокомпонентний. Найбільш частими побічними реакціями є: головний біль,
        запаморочення, непродуктивний подразнювальний кашель, бронхіт, синусит,
        задишка, запальні явища у шлунково-кишковому тракті, розлади травлення,
        дискомфорт у животі, диспепсія, діарея, нудота, блювання, висипання,
        зокрема макуло-папульозні, м’язові спазми, міалгія, підвищення рівня
        калію у крові, артеріальна гіпотензія, ортостатичне зниження
        артеріального тиску, синкопе, біль у грудях, втомлюваність.</span
      >

      <span
        >Контакти: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В,
        поверх 9; 0-800-502-284</span
      >

      <span>CBG-UA-00827. Термін дії матеріалу – 23.08.2024.</span>
    </div>

    <div v-show="!result.win && !result.lose" class="notes-wrapper desk">
      Інформація про лікарський засіб. РП №UA/16689/01/02, №UA/16689/01/03. Для
      професійної діяльності медичних та фармацевтичних працівників.

      <br />Раміприл-Тева (1 таблетка містить 5 мг або 10 мг раміприлу).
      Характеристики: таблетки по 5 мг: рожеві, продовгуваті, двоякоплоскі
      таблетки з двома лініями розлому з боків; верхній пуансон: паралельна
      лінія розлому, тиснення R/3, з фаскою; нижній пуансон: з фаскою; 8,8 мм х
      4,4 мм; таблетки по 10 мг: білі або майже білі, продовгуваті, двоякоплоскі
      таблетки з двома лініями розлому з боків; верхній пуансон: паралельна
      лінія розлому, тиснення R/4 та фаска; нижній пуансон: з фаскою; 11,0 мм х
      5,5 мм. Лікувальні властивості: інгібітор ангіотензинперетворювального
      ферменту (АПФ). Інгібітор АПФ монокомпонентний. Найбільш частими побічними
      реакціями є: головний біль, запаморочення, непродуктивний подразнювальний
      кашель, бронхіт, синусит, задишка, запальні явища у шлунково-кишковому
      тракті, розлади травлення, дискомфорт у животі, диспепсія, діарея, нудота,
      блювання, висипання, зокрема макуло-папульозні, м’язові спазми, міалгія,
      підвищення рівня калію у крові, артеріальна гіпотензія, ортостатичне
      зниження артеріального тиску, синкопе, біль у грудях, втомлюваність.

      <br />Контакти: ТОВ «Тева Україна», 02152, м. Київ, просп. П. Тичини, 1-В,
      поверх 9; 0-800-502-284

      <br />CBG-UA-00827. Термін дії матеріалу – 23.08.2024.
    </div>
  </div>
</template>

<script>
import Timer from "./Timer.vue";

export default {
  components: {
    timer: Timer,
  },

  data: () => ({
    time: 60,
    timer: null,
    winPopup: false,

    result: {
      win: false,
      lose: false,
    },

    text: "",
  }),

  computed: {
    cssVars() {
      return {
        "--width": this.widthBox,
      };
    },
    heightContent() {
      return {
        "--height": this.gameHeight,
      };
    },
    prettyTime() {
      let time = this.time / 60;
      let minutes = parseInt(time);
      let secondes = Math.round((time - minutes) * 60);
      return minutes + ":" + secondes;
    },
  },

  watch: {
    time(value) {
      if (value === 0) {
        this.onSubmit();
      }
    },
  },

  methods: {
    start() {
      if (this.timer) {
        clearInterval(this.timer);
      }

      this.timer = setInterval(() => {
        if (this.time > 0) {
          this.time--;
        } else {
          clearInterval(this.timer);
        }
      }, 1000);
    },

    reset() {
      this.time = 60;
      this.result.lose = false;
      this.result.win = false;

      this.text = "";
      window.scrollTo(0, 0)

      this.start();
    },

    onInput() {
      this.text = this.text.toUpperCase(); // .substr(0, 8)
    },

    onSubmit() {
      // setTimeout(() => {
        this.result[this.text === "РАМІПРИЛ" ? "win" : "lose"] = true;
      window.scrollTo(0, 0)
        clearInterval(this.timer);
      // }, 3000);
    },
  },

  mounted() {
    this.start();
  },
};
</script>

<style scoped lang="scss">
@import "../assets/style/fonts.css";
@import "../assets/style/vars";

.Ramipril {
  max-width: 1240px;
  width: 100%;

  display: flex;
  flex-direction: column;

  padding-top: 40px;

  min-height: calc((var(--vh, 1vh) * 100) - (var(--height_header)));
  height: 100%;
  margin: 0 auto;

  .game-stage1 {
    max-width: 650px;
    padding: 0 20px;

    margin: 0 auto;

    width: 100%;
  }

  .timer {
    font-family: $B;
  }

  .game-bar {
    display: flex;
    justify-content: space-between;

    max-width: 650px;
    padding: 0 20px 0 20px;
    margin: 0 auto;

    &__timer-section {
      margin: auto;
      display: flex;
      align-items: center;

      svg {
        max-width: 48px;
        width: 100%;
        display: block;
        margin-right: 10px;

        &.red {
          circle {
            stroke: #c40000;
          }

          path {
            stroke: #c40000;
          }
        }
      }
    }
  }

  .game {
    &__image {
      margin: 20px auto 20px;

      padding: 10px;
      width: 100%;
      text-align: center;
      background: #ffffff;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
      border-radius: 20px;

      img {
        width: 100%;
      }
    }

    &__form {
      margin-bottom: 10px;
      display: flex;
      align-items: center;

      input,
      button {
        border: none;
      }

      input {
        flex: 1 1 auto;
        width: 100%;

        display: flex;
        align-items: center;

        padding: 12px 12px;
        height: 60px;
        background: #ffffff;
        border: 1px solid $green;
        // box-shadow: 0 0 0 1px $green inset;
        outline: none;
        border-radius: 20px;

        font-size: 36px;
        line-height: 120%;
        // letter-spacing: 0.1em;
        text-align: center;

        &:focus {
          box-shadow: 0 0 0 1px $green inset;
        }
      }

      button {
        flex: 0 0 auto;
        margin-left: 15px;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background: #35786e;

        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          display: block;
        }

        &:disabled {
          opacity: 0.2;
        }
      }
    }

    &__tip {
      display: flex;
      align-items: center;
      justify-content: center;

      height: 40px;

      font-weight: 700;
      font-size: 14px;
      line-height: 120%;

      font-family: $M;

      .grey {
        color: $grey;
        opacity: 0.2;
      }

      .red {
        color: #c40000;
      }
    }
  }

  .lose {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 650px;
    width: 100%;

    padding: 0 20px 30px 20px;

    margin: 0 auto;

    &__red-block {
      background: #c40000;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      max-width: calc(100% - 40px);
      width: 100%;
      margin: 0 auto;
      padding: 25px 15px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
      border-radius: 14px;

      span {
        font-family: $EB;
      }
    }

    &__text {
      font-size: 20px;
      line-height: 120%;
      font-family: $M;
      color: white;
      text-align: center;
    }

    &__arrow-down {
      position: absolute;
      bottom: -9px;
      width: 0;
      left: 0px;
      right: 0;
      margin: 0 auto;
      height: 0;
      border-left: 24px solid transparent;
      border-right: 24px solid transparent;

      border-top: 10px solid #c40000;
    }

    &__img-circle {
      max-width: 358px;
      width: 100%;
      height: auto;
      display: block;
      margin: 6% auto 0 auto;
      border-radius: 50%;
    }

    &__repeat {
      margin: -1% auto 0 auto;
      border: 2px solid $green;
      border-radius: 35px;
      padding: 14px 15px;
      background: white;

      max-width: 230px;
      width: 100%;

      cursor: pointer;

      p {
        color: $green;

        font-family: $EB;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
      }
    }
  }

  .win {
    min-height: calc((var(--vh, 1vh) * 100) - (var(--height_header)) - 40px);

    position: relative;
    padding: 0 20px 0 20px;

    max-width: 650px;
    height: 100%;
    width: 100%;
    margin: 0 auto 0 auto;

    display: flex;
    flex-direction: column;

    &__green-block {
      background: $green;
      position: absolute;
      left: 0;
      right: 0;
      max-width: calc(100% - 40px);
      width: 100%;
      margin: 0 auto;
      padding: 25px 15px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
      border-radius: 14px;

      &.blue {
        background: $blue;
      }
    }

    &__text {
      font-size: 20px;
      line-height: 120%;
      font-family: $M;
      color: white;
      text-align: center;

      &.mob {
        display: none;
      }

      span {
        font-family: $EB;
      }
    }

    &__arrow-down {
      position: absolute;
      bottom: -9px;
      width: 0;
      left: 0px;
      right: 0;
      margin: 0 auto;
      height: 0;
      border-left: 24px solid transparent;
      border-right: 24px solid transparent;

      border-top: 10px solid $blue;
    }

    &__img-circle {
      max-width: 309px;
      width: 100%;
      height: auto;
      display: block;
      margin: 30px auto 0 auto;

      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
      border-radius: 50%;
    }

    &__text-center {
      text-align: center;
      margin-top: 40px;
      margin-bottom: 30px;
      color: $green;
      font-size: 20px;
      line-height: 120%;
      font-family: $B;
    }

    &__info-text-btn {
      margin: auto 0 40px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__info-text {
      max-width: 474px;
      width: 100%;
      margin-right: 15px;

      p {
        font-size: 16px;
        line-height: 120%;
        color: $grey;
        font-family: $M;
        vertical-align: center;
        position: relative;

        sup {
          font-size: 80%;
          line-height: 0;
          vertical-align: top;
        }
      }

      span {
        font-family: $EB;
      }
    }

    &__info-btn {
      margin-left: 15px;
      cursor: pointer;
    }

    &__popup {
      bottom: 40px;
      position: absolute;
      width: calc(100% - 20px);
      background: white;
      padding: 20px 20px 40px 20px;
      left: 50%;
      transform: translateX(-50%);

      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    }

    &__popup--close-btn {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 24px;

      svg {
        display: block;
        cursor: pointer;
      }
    }

    &__popup--text {
      font-size: 10px;
      line-height: 120%;
      font-family: $M;
      color: $grey;
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .notes-wrapper {
    max-width: 1280px;
    width: 100%;
    margin: auto auto 0 auto;

    padding: 16px 20px;

    font-size: 8px;
    line-height: 8px;
    font-family: $M;
    color: $grey;

    span {
      display: block;
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.active {
      color: white;
    }
  }

  @media screen and (max-width: 600px) {
    padding-top: 0;
    .game {
      &__tip {
        margin: auto 0 auto 0;
      }

      &__image {
        margin: 10px auto 20px;
      }
    }
    .game-stage1 {
      padding-top: 20px;
      min-height: calc((var(--vh, 1vh) * 100) - (var(--height_header)) - 20px);
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    .game-bar {
      &__timer-section {
        svg {
          max-width: 26px;
        }
      }
    }

    .lose {
      margin-top: 20px;
      padding-bottom: 0;


      &__red-block {
        font-size: 16px;
        line-height: 120%;
        padding: 15px;

        position: relative;
        top: 0;
      }

      &__text {
        font-size: 16px;
        line-height: 120%;
      }

      &__arrow-down {
      }

      &__img-circle {
        max-width: 100%;
        margin-top: -5%;
      }

      &__repeat {
        margin: -7% auto 0 auto;

        p {
        }
      }
    }

    .win {
      padding-top: 20px;
      padding-bottom: 20px;
      min-height: calc((var(--vh, 1vh) * 100) - (var(--height_header)));

      &__green-block {
        font-size: 16px;
        line-height: 120%;
        padding: 15px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__text {
        font-size: 16px;
        line-height: 120%;

        &.mob {
          display: block;
        }

        &.desk {
          display: none;
        }

        span {
        }
      }

      &__arrow-down {
      }

      &__img-circle {
        max-width: 360px;
        margin-top: 30px;
      }

      &__text-center {
        font-size: 16px;
        line-height: 120%;
        margin-bottom: 20px;
        margin-top: 20px;
      }

      &__info-text-btn {
        margin-bottom: 0;
      }

      &__info-text {
        p {
          font-size: 12px;
          line-height: 120%;

          sup {
          }
        }

        span {
        }
      }

      &__info-btn {
      }

      &__popup {
        padding: 18px 20px 18px 20px;
        bottom: 10px;
      }

      &__popup--close-btn {
        margin-bottom: 7px;

        svg {
        }
      }

      &__popup--text {
        font-size: 8px;
        line-height: 8px;
      }
    }

    .notes-wrapper {
      &.desk {
        display: none;
      }
    }
  }
}
</style>
